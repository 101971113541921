import React from "react";
import {
  About,
  Footer,
  Header,
  Projects,
  Testimonial,
  Work,
  Location,
} from "./container";
import { Navbar } from "./components";
import { useTranslation } from "react-i18next";
import { useLanguage } from "./LanguageContext"; // Импорт хука языка
import "./App.scss";

const App = () => {
  const { i18n } = useTranslation();
  const { selectedLanguage, setSelectedLanguage } = useLanguage(); // Получите хук языка

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
  };

  return (
    <div className="app">
      <Navbar
        selectedLanguage={selectedLanguage}
        changeLanguage={changeLanguage}
      />
      <Header selectedLanguage={selectedLanguage} />
      <About selectedLanguage={selectedLanguage} />
      <Work selectedLanguage={selectedLanguage} />
      <Projects selectedLanguage={selectedLanguage} />
      <Footer selectedLanguage={selectedLanguage} />
      <Location selectedLanguage={selectedLanguage} />
      <Testimonial selectedLanguage={selectedLanguage} />
    </div>
  );
};

export default App;
