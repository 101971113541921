import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";
import { useTranslation } from "react-i18next";
import "./Work.scss";

const Work = () => {
  const [works, setWorks] = useState([]);
  const [filterWork, setFilterWork] = useState([]);
  const [activeFilter, setActiveFilter] = useState("All");
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

  const { t, i18n } = useTranslation();
  const selectedLanguage = i18n.language;

  useEffect(() => {
    const query = '*[_type == "worker"]';

    client.fetch(query).then((data) => {
      setWorks(data);
      setFilterWork(data);
    });
  }, []);

  const handleWorkFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);

      if (item === "All") {
        setFilterWork(works);
      } else {
        const filteredWorks = works.filter((work) => {
          const tags = work.tags.map((tag) => tag[selectedLanguage]);
          return tags.includes(t(`filters.${item}`));
        });
        setFilterWork(filteredWorks);
      }
    }, 500);
  };

  return (
    <>
      <h2 className="head-text">
        {t("team.title")} <span>{t("team.span")}</span>
      </h2>

      <div className="app__work-filter">
        {[
          "CEO",
          "Architects",
          "Designers",
          "Accountants",
          "Other Staff",
          "All",
        ].map((item, index) => (
          <div
            key={index}
            onClick={() => handleWorkFilter(item)}
            className={`app__work-filter-item app__flex p-text ${
              activeFilter === item ? "item-active" : ""
            }`}
          >
            {t(`filters.${item}`)}
          </div>
        ))}
      </div>

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__work-portfolio"
      >
        {filterWork.map((worker, index) => {
          const title = worker.title[selectedLanguage];
          const description = worker.description[selectedLanguage];
          const tags = worker.tags[0][selectedLanguage];

          return (
            <a
              href={worker.projectLink}
              target="_blank"
              rel="noreferrer"
              key={index}
            >
              <div className="app__work-item app__flex" key={index}>
                <div className="app__work-img app__flex">
                  <img src={urlFor(worker.imgUrl)} alt={title} />
                </div>

                <div className="app__work-content app__flex">
                  <h4 className="bold-text">{title}</h4>
                  <p className="p-text" style={{ marginTop: 10 }}>
                    {description}
                  </p>

                  <div className="app__work-tag app__flex">
                    <p className="p-text">{tags}</p>
                  </div>
                </div>
              </div>
            </a>
          );
        })}
      </motion.div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Work, "app__works"),
  "team",
  "app__primarybg"
);
