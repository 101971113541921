import React, { useState } from "react";

import { images } from "../../constants";
import { AppWrap, MotionWrap } from "../../wrapper";
import { client } from "../../client";
import { useTranslation } from "react-i18next";
import "./Footer.scss";

const Footer = ({ selectedLanguage }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { username, email, message } = formData;
  const { t } = useTranslation();

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: "contact",
      name: formData.username,
      email: formData.email,
      message: formData.message,
    };

    client
      .create(contact)
      .then(() => {
        setLoading(false);
        setIsFormSubmitted(true);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <h2 className="head-text">
        {t("footer.started", { lng: selectedLanguage })}
        <span>{t("footer.span", { lng: selectedLanguage })}</span>
      </h2>

      <div className="app__footer-cards">
        <div className="app__footer-card ">
          <img src={images.email} alt="email" />
          <a href="mailto:rakhimov@webdevbahrom.me" className="p-text">
            rakhimov@webdevbahrom.me
          </a>
        </div>
        <div className="app__footer-card">
          <img src={images.mobile} alt="phone" />
          <a href="tel:+998 (90) 186-97-91" className="p-text">
            +998 (90) 186-97-91
          </a>
        </div>
      </div>
      {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <input
              className="p-text"
              type="text"
              placeholder={t("footer.namePlaceholder", {
                lng: selectedLanguage,
              })}
              name="username"
              value={username}
              onChange={handleChangeInput}
            />
          </div>
          <div className="app__flex">
            <input
              className="p-text"
              type="email"
              placeholder={t("footer.emailPlaceholder", {
                lng: selectedLanguage,
              })}
              name="email"
              value={email}
              onChange={handleChangeInput}
            />
          </div>
          <div>
            <textarea
              className="p-text"
              placeholder={t("footer.messagePlaceholder", {
                lng: selectedLanguage,
              })}
              value={message}
              name="message"
              onChange={handleChangeInput}
            />
          </div>
          <button type="button" className="p-text" onClick={handleSubmit}>
            {!loading
              ? t("footer.sendButton", { lng: selectedLanguage })
              : t("footer.sendingButton", { lng: selectedLanguage })}
          </button>
        </div>
      ) : (
        <div>
          <h3 className="head-text">
            {t("footer.thankYouMessage", { lng: selectedLanguage })}
          </h3>
        </div>
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, "app__footer"),
  "contact",
  "app__primarybgg"
);
