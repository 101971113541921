import React from "react";
import { motion } from "framer-motion";
import { images } from "../../constants";
import { AppWrap } from "../../wrapper";
import { useTranslation } from "react-i18next"; // Импортируем хук для работы с i18next
import "./Header.scss";

const Header = () => {
  const { t } = useTranslation(); // Используем хук для доступа к переводам

  return (
    <div className="projects-container">
      <div className="app__header app__flex">
        <motion.div
          whileInView={{ x: [-100, 0], opacity: [0, 1] }}
          transition={{ duration: 1 }}
          className="app__header-info"
        >
          <h1>{t("header.title")}</h1>
          <p>{t("header.description")}</p>
          <button>
            <a href="#contact">{t("header.cta")}</a>
          </button>
        </motion.div>

        <div className="app__header-img slide-top">
          <motion.img
            src={images.herobcg}
            alt="Image 1"
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 1 }}
          />
        </div>
      </div>
    </div>
  );
};

export default AppWrap(Header, "home");
