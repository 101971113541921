import React from "react";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";
import { useTranslation } from "react-i18next";
import "./Location.scss";

const Location = () => {
  const { t } = useTranslation();

  return (
    <div className="app__location">
      <h2 className="head-text">
        {t("locations.title")} <span>{t("locations.span")}</span>
      </h2>
      <motion.div
        className="location-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="image-container">
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3363.943666649963!2d69.24049198821788!3d41.310317156856335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8b08be09adf5%3A0x9b1fe3b85bf90531!2zOCDRg9C70LjRhtCwINCk0YPRgNC60LDRgtCwLCDQotCw0YjQutC10L3Rgiwg0KPQt9Cx0LXQutC40YHRgtCw0L0!5e0!3m2!1sru!2s!4v1691829164043!5m2!1sru!2s"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="location-details">
          <h2>{t("locations.address")}</h2>
          <p>{t("locations.addressDetails")}</p>
        </div>
      </motion.div>
    </div>
  );
};

export default AppWrap(
  MotionWrap(Location, "app__location"),
  "location",
  "app__whitebg"
);
