import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import enTranslation from "./locales/English.json";
import uzbTranslation from "./locales/O'zbek tili.json";
import deTranslation from "./locales/Deutsch.json";
import ruTranslation from "./locales/Русский.json";
import trTranslation from "./locales/Türkçe.json";

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      uz: {
        translation: uzbTranslation,
      },
      de: {
        translation: deTranslation,
      },
      ru: {
        translation: ruTranslation,
      },
      tr: {
        translation: trTranslation,
      },

      en: {
        translation: enTranslation,
      },
    },
    fallbackLng: "en",
    debug: false,

    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["cookie"],
    },

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
