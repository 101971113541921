import React, { useState, useEffect } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { motion } from "framer-motion";

import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";
import { useTranslation } from "react-i18next";
import "./Projects.scss";

const Projects = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [projects, setProjects] = useState([]);
  const { t, i18n } = useTranslation();

  const selectedLanguage = i18n.language;

  const handleClick = (index) => {
    setCurrentIndex(index);
    setCurrentImageIndex(0);
  };

  const handleNextImage = () => {
    const imagesCount = projects[currentIndex]?.images?.length || 0;
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imagesCount);
  };

  const handlePrevImage = () => {
    const imagesCount = projects[currentIndex]?.images?.length || 0;
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + imagesCount) % imagesCount
    );
  };

  useEffect(() => {
    const query = '*[_type == "projects"]';

    client.fetch(query).then((data) => {
      setProjects(data);
    });
  }, []);

  // Обновляем данные в состоянии при изменении языка
  useEffect(() => {
    const query = '*[_type == "projects"]';

    client.fetch(query).then((data) => {
      setProjects(data);
      setCurrentIndex(0);
      setCurrentImageIndex(0);
    });
  }, [selectedLanguage]); // Этот эффект будет запускаться, когда selectedLanguage изменяется

  return (
    <>
      <h2 className="head-text">
        {t("projects.title")} <span>{t("projects.span")}</span>
      </h2>

      {projects.length > 0 && (
        <div className="projects-container">
          <motion.div
            key={projects[currentIndex]._id}
            className="project-item"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div className="image-container">
              <motion.div className="image-slideshow">
                <motion.img
                  src={urlFor(projects[currentIndex].images[currentImageIndex])}
                  alt={projects[currentIndex].name[selectedLanguage]}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                />
              </motion.div>
              <div className="image-navigation">
                <div className="prev" onClick={handlePrevImage}>
                  <HiChevronLeft />
                </div>
                <div className="next" onClick={handleNextImage}>
                  <HiChevronRight />
                </div>
              </div>
            </motion.div>
            <motion.div
              className="project-details"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <h2>{projects[currentIndex].name[selectedLanguage]}</h2>
              <p>
                {t("projects.location")}:
                <a
                  href={projects[currentIndex].locationLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("projects.discoverLocation")}
                </a>
              </p>
              <p>
                {t("projects.date")}: {projects[currentIndex].date}
              </p>
              <p>
                {t("projects.architectureStyle")}:{" "}
                {projects[currentIndex].architecturestyle[selectedLanguage]}
              </p>
              <p>
                {t("projects.designStyle")}:{" "}
                {projects[currentIndex].designstyle[selectedLanguage]}
              </p>
              <div className="building-characteristics">
                <h3>{t("projects.buildingCharacteristics")}</h3>
                <p>
                  {t("projects.areaLand")}:{" "}
                  {projects[currentIndex].buildingCharacteristics.area}
                  {t("projects.squareMeter")}
                </p>
                <p>
                  {t("projects.floors")}:{" "}
                  {projects[currentIndex].buildingCharacteristics.floors}
                </p>
                <p>
                  {t("projects.buildingHeight")}:{" "}
                  {projects[currentIndex].buildingCharacteristics.height}
                  {t("projects.meter")}
                </p>
                <p>
                  {t("projects.materials")}:{" "}
                  {projects[currentIndex].buildingCharacteristics.materials.map(
                    (material) => material[selectedLanguage]
                  )}
                </p>
              </div>
            </motion.div>
          </motion.div>

          <motion.div
            className="navigation-btns app__flex"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div
              className="app__flex"
              onClick={() =>
                handleClick(
                  currentIndex === 0 ? projects.length - 1 : currentIndex - 1
                )
              }
            >
              <HiChevronLeft />
            </div>
            <div
              className="app__flex"
              onClick={() =>
                handleClick(
                  currentIndex === projects.length - 1 ? 0 : currentIndex + 1
                )
              }
            >
              <HiChevronRight />
            </div>
          </motion.div>
        </div>
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Projects, "app__testimonial"),
  "projects",
  "app__whitebg "
);
