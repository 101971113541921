import React, { useState } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { images } from "../../constants";
import { AiOutlineClose } from "react-icons/ai";
import { MdLanguage } from "react-icons/md";
import "./Navbar.scss";

const languageNames = {
  en: "English",
  ru: "Русский",
  uz: "O'zbek tili",
  de: "Deutsch",
  tr: "Türkçe",
};

const Navbar = ({ selectedLanguage, changeLanguage }) => {
  const [toggle, setToggle] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    setToggleMenu(false);
  };

  return (
    <nav className="app_navbar">
      <div className="app__navbar-logo">
        <img src={images.logo} alt="logo" />
      </div>

      <ul className="app__navbar-links">
        {["home", "about", "team", "projects", "contact", "location"].map(
          (item) => (
            <li className="app_flex p-text" key={`link-${item}`}>
              <a href={`#${item}`}>{t(`navbar.${item}`)}</a>
            </li>
          )
        )}
      </ul>

      <div className="gpt3__navbar-menu">
        {toggleMenu ? (
          <AiOutlineClose
            color="#006eff"
            size={24}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <div className="change-language" onClick={() => setToggleMenu(true)}>
            <div className="language-icon">
              <MdLanguage color="#006eff" size={28} />
            </div>
            <span>{t("navbar.change-icon")}</span>
          </div>
        )}

        {toggleMenu && (
          <div className="gpt3__navbar-menu_container scale-up-center">
            <div className="gpt3__navbar-menu_container-links">
              <ul>
                {["en", "ru", "uz", "de", "tr"].map((lng) => (
                  <li key={lng}>
                    <button onClick={() => handleLanguageChange(lng)}>
                      {languageNames[lng].toUpperCase()}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>

      <div className="navbar-menu">
        {toggle ? (
          <RiCloseLine
            color="#006eff"
            size={27}
            onClick={() => setToggle(false)}
          />
        ) : (
          <RiMenu3Line
            color="#006eff"
            size={27}
            onClick={() => setToggle(true)}
          />
        )}

        {toggle && (
          <div className="navbar-menu_container scale-up-center">
            <div className="navbar_links-container">
              <ul>
                {[
                  "home",
                  "about",
                  "team",
                  "projects",
                  "contact",
                  "location",
                ].map((item) => (
                  <li key={item}>
                    <a href={`#${item}`} onClick={() => setToggle(false)}>
                      {t(`navbar.${item}`)}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
