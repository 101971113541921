import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";
import { useTranslation } from "react-i18next";
import "./About.scss";

const About = () => {
  const [abouts, setAbouts] = useState([]);
  const { t, i18n } = useTranslation();

  const selectedLanguage = i18n.language; // Получаем выбранный язык

  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client.fetch(query).then((data) => {
      setAbouts(data);
    });
  }, []);

  return (
    <>
      <h2 className="head-text">
        {t("about.title")} <span>{t("about.company")}</span>
      </h2>

      <div className="app__profiles">
        {abouts.map((about, index) => {
          const title = about.title[selectedLanguage];
          const description = about.description[selectedLanguage];

          return (
            <motion.div
              whileInView={{ opacity: 1 }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.5, type: "tween" }}
              className="app__profile-item"
              key={about.title + index}
            >
              <div className="image-container">
                <img src={urlFor(about.imgUrl)} alt={title} />
              </div>
              <h2 className="bold-text">{title}</h2>
              <div className="description-container">
                <p className="p-text">{description}</p>
              </div>
            </motion.div>
          );
        })}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, "app__about"),
  "about",
  "app__whitebg"
);
